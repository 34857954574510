<template>
  <div id="app-container" class="font-['Prompt'] container relative mx-0 sm:mx-auto sm:max-w-sm md:border prevent-select" 
    style=" height : 100vh" 
  >
    <ToastGlobalToast />
    <ToastThankyou />
    <LoadingGlobal />
    <StepProgressBar :step="step"/>
    <NavsTop
      v-if="isShowNavBar"
      :label="title"
      :step="step"
      :backFunc="backToThankyou ? goToThankyou : back"
    />

    <!-- <StepBar :step="step" /> -->

    <div class="  bg-slate-100">
      <slot></slot>
    </div>

    <NavsFooter
      v-if="isShowFooter"
      :isShowBack="isShowBack"
      :isShowNext="isShowNext"
      :labelBack="$t('buttonBack')"
      :labelNext="$route.name == 'editor' ? $t('buttonEditorNext') : $t('buttonNext')"
      @back="back"
      @next="next"
    />
  </div>

  <div 
    v-if="appStateStore.landedIndexPage === false && routeName !== 'index'" 
    class="absolute top-0 bottom-0 right-0 left-0 bg-white z-50 w-full h-full"
  >
    <div class="flex justify-center items-center w-full h-full">
      <p class="text-2xl text-gray-700">Loading...</p>
    </div>
  </div>
</template>

<script lang="ts" setup>

  import { useAppStateStore } from "../store/appState";
  import { useWishStore } from "../store/wish";
  import { useEventStore } from "../store/event";

  import _includes from "lodash/includes.js";
  import _find from "lodash/find.js";
  import _map from "lodash/map.js";

  import { i18n } from "~/plugins/i18n";
  import { ALL_FLOWS } from "~/static/data/allFlow";

  const appStateStore = useAppStateStore();
  const wishStore = useWishStore();
  const eventStore = useEventStore();

  const $route = useRoute();
  const $router = useRouter();

  const viewPageData = computed(() => appStateStore.view)
  const routeName = computed(() => $route.name)

  const isShowNavBar = ref(false);
  const isShowFooter = ref(false);
  const isShowBack = ref(true);
  const isShowNext = ref(true);
  const title = ref("Title");
  const backToThankyou = ref(false);
  const step = ref({}); //{ currentStep: 0, stepList: []}

  useHead({
  bodyAttrs: {
    style : [
      // "touch-action : none",
      "overscroll-behavior : none"
    ]
  }
})
  watch(routeName, async(newPath) => {
    const path:any = newPath
    const isTargetPage = isSpecialPage(path);
    if (isTargetPage) {
      return;
    }

    // detect flow navbar & footer by history path
    const historyData: any = appStateStore?.nextFlow?.history
    const isCorrectPath = _includes(historyData.path, path);
    if (!isCorrectPath) {
      await back();
    }

    updateView();
  });

  onMounted(() => {
    checkReloadApp();
    updateView();
  });

  const checkReloadApp = () => {
    setTimeout(() => {
      if(appStateStore.landedIndexPage == false) {
        window.location.href = window.location.origin + "/"
      }
    }, 50);
  };

  const isSpecialPage = (targetPage: string) => {
    const lang = i18n.global.locale.value;
    if (targetPage == "mywish" || targetPage == "preview" || targetPage == "promptpay" || targetPage == "promptpay-attach") {
      const viewData = _find(ALL_FLOWS, { path: "/" + targetPage });

      if(wishStore?.isSendWish) {
        backToThankyou.value = true
      } else {
        backToThankyou.value = false
      }

      isShowNavBar.value = viewData.navbar;
      step.value = {};

      if (lang == "th") {
        title.value = viewData.titleTH;
      } else {
        title.value = viewData.titleEN;
      }
      return true;
    }

    return false;
  };

  const goToThankyou = () => {
    wishStore.wishPreviewData = null
    const path = `/thankyou?eventId=${eventStore.eventId}`
    return $router.push(path);
  }

  const updateFlowList = () => {
    const list = _map(appStateStore?.flowList, (flow: any) => {
      
      return {
        ...flow,
        stepTitle: appStateStore?.locale == 'th' ? flow.stepTitleTH : flow.stepTitleEN
      }
    });

    return list;
  };

  const updateView = () => {
    const lang = i18n.global.locale.value;
    const viewPage: any = viewPageData.value//appStateStore?.view;
    backToThankyou.value = false;
    isShowNavBar.value = viewPage.navbar;
    isShowFooter.value = viewPage.footer;
    isShowBack.value = viewPage.backButton;
    isShowNext.value = viewPage.nextButton;

    let stepTarget = appStateStore?.flowType+ "_step";
    step.value = {
      currentStep: viewPage[stepTarget],
      stepList: updateFlowList(),
    };

    if (lang == "th") {
      title.value = viewPage.titleTH;
    } else {
      title.value = viewPage.titleEN;
    }
  };

  const back = async () => {
    await appStateStore.goBack();
  };

  const next = async () => {
     
    await appStateStore.goNext();
  };
</script>
<!-- 
<style scoped>

 body {
  overflow: hidden;
}
#app-container {
  height: 100svh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}  
</style> -->